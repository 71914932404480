import MPButtonGroup from "../MPButtonGroup/MPButtonGroup";
import MPButton, { colorOptions } from "../MPButton/MPButton";
import { ConfirmCancelModalProps } from "./ConfirmCancelModal";

export type ConfirmCancelModalContentProps = Omit<
  ConfirmCancelModalProps,
  | "isOpen"
  | "className"
  | "modalHeader"
  | "confirmButtonColor"
  | "cancelButtonColor"
> & {
  confirmButtonColor: colorOptions;
  cancelButtonColor: colorOptions;
};

const ConfirmCancelModalContent = ({
  children,
  cancelButtonText,
  confirmButtonText,
  isConfirmButtonDisabled,
  onCancelButtonPress,
  onConfirmButtonPress,
  confirmButtonColor,
  cancelButtonColor,
}: ConfirmCancelModalContentProps) => {
  return (
    <>
      <div className="confirm-cancel-modal-content">{children}</div>
      <MPButtonGroup
        className="modal-buttons"
        buttons={[
          <MPButton
            isDisabled={isConfirmButtonDisabled}
            key="0"
            buttonType="colored"
            color={confirmButtonColor}
            onClick={onConfirmButtonPress}
            text={confirmButtonText}
          />,
          <MPButton
            key="1"
            buttonType="colored"
            color={cancelButtonColor}
            onClick={onCancelButtonPress}
            text={cancelButtonText}
          />,
        ]}
      />
    </>
  );
};

export default ConfirmCancelModalContent;
