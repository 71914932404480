type CustomEventDispatcherParameter = {
  name: string;
  detail?: { [key: string]: any };
};
/**
 *
 * @param name: name of custom event
 * @param detail: optional
 */
export const customEventDispatcher = ({
  name,
  detail = {},
}: CustomEventDispatcherParameter) => {
  const event = new CustomEvent(name, {
    detail,
  });
  document.dispatchEvent(event);
};
