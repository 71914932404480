import * as React from "react";
import Modal from "../Modal/Modal";
import { colorOptions } from "../MPButton/MPButton";
import "./ConfirmCancelModal.scss";
import ConfirmCancelModalContent from "./ConfirmCancelModalContent";

export type ConfirmCancelModalProps = {
  /**
   * open or close the modal
   */
  isOpen: boolean;
  /**
   * custom class for the modal itself, optional
   * in the modal we have 2 sub classes, modal-header and modal-body
   * the root div has modal-root class, with two children, .modal-overlay and .modal, this could come handy incase of the need to add z-index for some reason
   */
  className?: string;
  /**
   * optional: modal header, it will be wrapped by h1 element
   */
  modalHeader?: string;
  /**
   * required: modal body
   */
  children: React.ReactElement | string;
  /**
   * required: cancel button text
   */
  cancelButtonText: string | React.ReactElement;
  /**
   * required: confirm button text
   */
  confirmButtonText: string | React.ReactElement;
  /**
   * The color of the confirm button (defaults to `primary')
   */
  confirmButtonColor?: colorOptions;
  /**
   * The color of the cancel button (defaults to `normal')
   */
  cancelButtonColor?: colorOptions;
  /**
   * Whether or not the confirm button is disabled
   */
  isConfirmButtonDisabled?: boolean;
  /**
   * function to handle Cancel button
   */
  onCancelButtonPress: () => void;
  /**
   * function to handle Confirm button
   */
  onConfirmButtonPress: () => void;
};

export const ConfirmCancelModal = ({
  isOpen,
  className,
  modalHeader = "",
  cancelButtonText,
  confirmButtonText,
  isConfirmButtonDisabled = false,
  onCancelButtonPress,
  onConfirmButtonPress,
  confirmButtonColor = "primary",
  cancelButtonColor = "normal",
  children,
}: ConfirmCancelModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      isClosable={false}
      header={modalHeader ? <h1>{modalHeader}</h1> : ""}
      className={`${className} confirm-cancel-modal`}
    >
      <ConfirmCancelModalContent
        cancelButtonText={cancelButtonText}
        confirmButtonText={confirmButtonText}
        confirmButtonColor={confirmButtonColor}
        cancelButtonColor={cancelButtonColor}
        isConfirmButtonDisabled={isConfirmButtonDisabled}
        onCancelButtonPress={onCancelButtonPress}
        onConfirmButtonPress={onConfirmButtonPress}
      >
        {children}
      </ConfirmCancelModalContent>
    </Modal>
  );
};

export default ConfirmCancelModal;
