import * as React from "react";
import "./ConfirmCancelMessage.scss";
import { colorOptions } from "@oldstyle-shared/components/UI/MPButton/MPButton";
import ConfirmCancelModal from "@oldstyle-shared/components/UI/ConfirmCancelModal/ConfirmCancelModal";
import MPBasicStylePage from "@oldstyle-shared/components/UI/MPBasicStylePage/MPBasicStylePage";
import parse from "html-react-parser";
import useCustomEvent from "@oldstyle-shared/Hooks/CustomEvent";

export type ConfirmCancelMessageEvent = {
  /**
   * optional: if passed it will be enclosed by h1 tag
   */
  messageHeader?: string;
  /**
   * message body
   */
  messageText: string;
  onCancel?: () => void;
  onConfirm: () => void;
};

export type ConfirmCancelMessageProps = {
  /**
   * the component is going to listen to this event to change open state to true
   */
  openEventName: string;

  cancelButtonText: string;
  confirmButtonText: string;
  /**
   * follows MP color scheme, optional, default is "primary"
   */
  confirmButtonColor?: colorOptions;
  /**
   * follows MP color scheme, optional, default is "normal"
   */
  cancelButtonColor?: colorOptions;
};

export const ConfirmCancelMessage = ({
  openEventName,
  cancelButtonText,
  confirmButtonText,
  confirmButtonColor = "primary",
  cancelButtonColor = "normal",
}: ConfirmCancelMessageProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { messageHeader, messageText, onCancel, onConfirm } =
    useCustomEvent<ConfirmCancelMessageEvent>(openEventName, () => {
      setIsOpen(true);
    });

  const handleCancelButtonPress = () => {
    setIsOpen(false);
    onCancel && onCancel();
  };

  const handleConfirmButtonPress = () => {
    setIsOpen(false);
    onConfirm();
  };

  return (
    <MPBasicStylePage>
      <ConfirmCancelModal
        isOpen={isOpen}
        modalHeader={messageHeader ? messageHeader : ""}
        cancelButtonText={parse(cancelButtonText) as string}
        confirmButtonText={parse(confirmButtonText) as string}
        onCancelButtonPress={handleCancelButtonPress}
        onConfirmButtonPress={handleConfirmButtonPress}
        confirmButtonColor={confirmButtonColor}
        cancelButtonColor={cancelButtonColor}
        className="confirm-cancel-message"
      >
        <div>{messageText ? parse(messageText) : ""}</div>
      </ConfirmCancelModal>
    </MPBasicStylePage>
  );
};
export default ConfirmCancelMessage;
