import * as React from "react";
import * as ReactDOM from "react-dom";

import "./Modal.scss";
import MarketplaceIcon from "../MarketplaceIcon/marketplaceIcon";

type EssentialModalProps = {
  /**
   * open or close the modal
   */
  isOpen: boolean;
  /**
   * custom class for the modal itself, optional
   * in the modal we have 2 sub classes, modal-header and modal-body
   * the root div has modal-root class, with two children, .modal-overlay and .modal, this could come handy incase of the need to add z-index for some reason
   */
  className?: string;
  /**
   * modal header, optional
   */
  header?: React.ReactElement | string;
  /**
   * modal content,
   */
  children: React.ReactElement | string;
};
type ModalProps =
  | (EssentialModalProps & {
      isClosable: false;
      /**
       *aria label and title for close button:  not needed when isClosable is false
       */
      closeButtonAriaLabel?: never;
      /**
       * function to handle close modal
       */
      onClose?: () => void;
    })
  | (EssentialModalProps & {
      /**
       * ,required: show or hide close button from the header
       */
      isClosable: true;
      /**
       *  required when isClosable is true
       */
      closeButtonAriaLabel: string;
      /**
       * function to handle close modal
       */
      onClose: () => void;
    });

export const Modal = ({
  isOpen,
  onClose,
  isClosable,
  closeButtonAriaLabel,
  className,
  header = "",
  children,
}: ModalProps) => {
  if (isOpen) {
    return ReactDOM.createPortal(
      <div className="modal-root">
        <div className="modal-overlay"></div>
        <div className="modal-wrap">
          <div className="modal-box">
            <div className={`modal ${className}`}>
              {(header != "" || isClosable) && (
                <div className="modal-header">
                  <div>{header}</div>
                  {isClosable ? (
                    <button
                      aria-label={closeButtonAriaLabel}
                      title={closeButtonAriaLabel}
                      onClick={onClose}
                    >
                      <MarketplaceIcon name="close" />
                    </button>
                  ) : (
                    <div></div>
                  )}
                </div>
              )}
              <div className="modal-body">{children}</div>
            </div>
          </div>
        </div>
      </div>,
      document.getElementById("modals") as Element,
    );
  } else {
    return null;
  }
};

export default Modal;
