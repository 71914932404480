import type { ReactElement } from "react";
import "./MPBasicStylePage.scss";

/**
 * just a wrapper element to apply the general style between react pages and other styles needed to counter the affect of site.css
 */

const MPBasicStylePage = ({ children }: { children: ReactElement }) => {
  return <div className="react-page">{children}</div>;
};

export default MPBasicStylePage;
