import type { ReactElement } from "react";
import ReactDOM from "react-dom/client";

type RenderComponentToHTMLTagParameter = {
  htmlElement: HTMLElement;
  component: ReactElement;
};

const renderComponentToTag = ({
  htmlElement,
  component,
}: RenderComponentToHTMLTagParameter) => {
  const root = ReactDOM.createRoot(htmlElement);
  root.render(component);
};

export { renderComponentToTag };
