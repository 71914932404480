import "./marketplaceIcon.scss";

export type MarketplaceIconProps = {
  /**
   * the name of the icon, as it is in Marketplace font, no need to add "icon-" before the name
   */
  name: string;
  /**
   * class to pass to the component for styling, testing,...
   */
  className?: string;
};

export const MarketplaceIcon = ({ name, className }: MarketplaceIconProps) => {
  return <i className={`${className} marketplace-icon icon-${name}`}></i>;
};

export default MarketplaceIcon;
