import { z } from "zod";
import { logErrorToServer } from "./logger";

declare const IS_TYPE_VALIDATING_IN_REALTIME: boolean;

type MPRunTimeTypeValidationParameter = {
  parameter: unknown;
  schema: z.ZodTypeAny;
};
const mpRunTimeTypeValidation = ({
  parameter,
  schema,
}: MPRunTimeTypeValidationParameter) => {
  if (!IS_TYPE_VALIDATING_IN_REALTIME) {
    return;
  }
  const result = schema.safeParse(parameter);
  if (!result.success) {
    logErrorToServer({
      message: `error type:  Validation Error,\nerror details: ${JSON.stringify(
        result.error.format(),
        null,
        2,
      )}`,
    });
    throw `failed validation\n${result.error}`;
  }
};

export { mpRunTimeTypeValidation };
