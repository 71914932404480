import * as React from "react";
/**
 * `useCustomEvent` Returns a stateful value
 * @param eventName: custom event name to listen to
 * @param handleCustomEvent (optional)  a function runs when the event is triggered
 * usage example:
 * const { onConfirmHideTitles } = useCustomEvent<openHideTitlesModalEvent>("openHideTitlesModal", () => {
 *   setIsOpen(true);
 *   });
 */

const useCustomEvent = <T = void>(
  eventName: string,
  handleCustomEvent?: () => void,
): T => {
  const [detail, setDetail] = React.useState<T>({} as T);
  React.useEffect(() => {
    const customEventListenerMethod = (e: CustomEvent) => {
      setDetail(e.detail);
      handleCustomEvent && handleCustomEvent();
    };
    document.addEventListener(
      eventName,
      customEventListenerMethod as EventListener,
    );
    return () =>
      document.removeEventListener(
        eventName,
        customEventListenerMethod as EventListener,
        false,
      );
  }, []);
  return detail;
};

export default useCustomEvent;
