import React from "react";
import "./MPButtonGroup.scss";
import MPButton from "../MPButton/MPButton";

export type alignmentOptions = "left" | "center" | "right";

type MPButtonGroupProps = {
  /**
   * An array of button in the group, unique key is required for each element
   */
  buttons: React.ReactElement<typeof MPButton>[];
  /**
   * location of the group, optional: default: left
   */
  alignment?: alignmentOptions;
  /**
   *  optional: class name for button element
   */
  className?: string;
};

export const MPButtonGroup = ({
  buttons,
  alignment = "left",
  className,
}: MPButtonGroupProps) => {
  return (
    <div className={`button-group alignment-${alignment} ${className}`}>
      {<>{buttons}</>}
    </div>
  );
};

export default MPButtonGroup;
