import React from "react";
import "./MPButton.scss";

export const availableColorOptions = [
  "normal",
  "primary",
  "blue",
  "green",
  "yellow",
  "orange",
  "red",
  "red-preorder",
  "brown",
] as const;
export type colorOptions = (typeof availableColorOptions)[number];

export const availableButtonTypeOptions = ["colored", "hollow"] as const;
export type buttonTypeOptions = (typeof availableButtonTypeOptions)[number];

type MPButtonProps = {
  /**
   * control color of the button, based on OverDrive Marketplace Style Guide
   */
  color: colorOptions;
  /**
   * type of the button, based on OverDrive Marketplace Style Guide, optional, default colored
   */
  buttonType?: buttonTypeOptions | undefined;
  /**
   *  text in the button, it can be a child element
   */
  text: string | React.ReactElement;
  /**
   *  optional: class name for button element
   */
  className?: string | undefined;
  /**
   *  optional: default is false
   */
  isDisabled?: boolean | undefined;
  /**
   *  handle click event
   */
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export const MPButton = ({
  color,
  buttonType = "colored",
  text,
  className,
  isDisabled = false,
  onClick,
}: MPButtonProps) => {
  return (
    <button
      className={`mp-button ${color} ${buttonType} ${className}`}
      disabled={isDisabled}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default MPButton;
