import ConfirmCancelMessage, {
  ConfirmCancelMessageEvent,
  ConfirmCancelMessageProps,
} from "./ConfirmCancelMessage/ConfirmCancelMessage";
import {
  customEventDispatcher,
  mpRunTimeTypeValidation,
  renderComponentToTag,
} from "@common/Utilities";

import { z } from "zod";

const colorOptionsSchema = z.enum([
  "normal",
  "primary",
  "blue",
  "green",
  "yellow",
  "orange",
  "red",
  "red-preorder",
  "brown",
]);

const schema = z.object({
  cancelButtonText: z.string(),
  confirmButtonText: z.string(),
  confirmButtonColor: colorOptionsSchema.optional(),
  cancelButtonColor: colorOptionsSchema.optional(),
});

const openConfirmCancelMessageSchema = z.object({
  messageHeader: z.string().optional(),
  messageText: z.string(),
  onCancel: z.function().optional(),
  onConfirm: z.function(),
});

type MPConfirmCancelMessageParameter = Omit<
  ConfirmCancelMessageProps,
  "openEventName"
>;

type OpenConfirmCancelMessageParameters = ConfirmCancelMessageEvent;

class MPConfirmCancelMessage {
  #openEventName: string;
  constructor(props: MPConfirmCancelMessageParameter) {
    mpRunTimeTypeValidation({ parameter: props, schema });
    this.#openEventName = Math.random().toString(36).substring(2);
    const div = document.createElement("div");
    const modalsDiv = document.getElementById("modals") as HTMLElement;
    modalsDiv.appendChild(div);
    renderComponentToTag({
      htmlElement: div,
      component: (
        <ConfirmCancelMessage openEventName={this.#openEventName} {...props} />
      ),
    });
  }

  public open(parameter: OpenConfirmCancelMessageParameters) {
    mpRunTimeTypeValidation({
      parameter,
      schema: openConfirmCancelMessageSchema,
    });
    customEventDispatcher({
      name: this.#openEventName,
      detail: parameter,
    });
  }
}

export { MPConfirmCancelMessage };
